import React, { useState, useEffect, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  ApiKeyModel,
  BillingPlan,
  ConnectMarselloViewModel,
  CreateApiKeyModel,
  EditApiKeyModel,
  IntegrationApi,
  IntegrationViewModel,
  KlaviyoIntegrationViewModel,
  LoopIntegrationViewModel,
  SegmentIntegrationViewModel,
  SlackChannelDto,
  SlackIntegrationViewModel,
  ToggleIntegrationViewModel,
  ZendeskIntegrationViewModel,
} from "../../apiclient";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import * as Swal from "sweetalert2";
import UpsellBanner from "../../components/UpsellBanner";
import { Button, Row, Col, InputGroup, Modal, Form } from "react-bootstrap";
import { useCopyToClipboard } from "react-use";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import { shopifyAppBridge } from "../../config";
import SettingsNavMenu from "../../components/SettingsNavMenu";
import MaskedText from "../../components/MaskedText";
import ProPlanUpsellBanner from "../../components/ProPlanUpsellBanner";

const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const IntegrationsPage = () => {
  let integrationApi = new IntegrationApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [model, setModel] = useState({} as IntegrationViewModel);


  const authContext = useContext(AuthContext);
  const [showKlaviyoSettingsModal, setShowKlaviyoSettingsModal] = useState(
    false as boolean
  );
  const [showLoopSettingsModal, setShowLoopSettingsModal] = useState(
    false as boolean
  );
  const [showKlaviyoFlowModal, setShowKlaviyoFlowModal] = useState(
    false as boolean
  );
  const [showSegmentSettingsModal, setShowSegmentSettingsModal] = useState(
    false as boolean
  );
  const [showZendeskSettingsModal, setShowZendeskSettingsModal] = useState(
    false as boolean
  );
  const [showSlackSettingsModal, setShowSlackSettingsModal] = useState(
    false as boolean
  );
  const [showZapierInstallModal, setShowZapierInstallModal] = useState(
    false as boolean
  );

  const [showMarselloInstallModal, setShowMarselloInstallModal] = useState(false as boolean);
  const [showMarselloPassword, setShowMarselloPassword] = useState(false as boolean);

  const [showMarselloRewardSettingsModal, setShowMarselloRewardSettingsModal] = useState(false as boolean);

  const [showCreateApiKeyModal, setShowCreateApiKeyModal] = useState(false as boolean);
  const [createApiKeyDescription, setCreateApiKeyDescription] = useState('' as string);

  const [showEditApiKeyModal, setShowEditApiKeyModal] = useState(false as boolean);
  const [editApiKeyModel, setEditApiKeyModel] = useState({} as ApiKeyModel);

  const [loopWebhookUrl, setLoopWebhookUrl] = useState("" as string);
  const [isSubmitting, setIsSubmitting] = useState(false as boolean);
  const [validated, setValidated] = useState(false as boolean);
  const [slackChannels, setSlackChannels] = useState([] as SlackChannelDto[]);
  const [searchParams, setSearchParams] = useSearchParams();
  const hasSlackConnected = searchParams.get("slackConnected") ?? "";
  const [apiKeys, setApiKeys] = useState([] as ApiKeyModel[]);

  var navigate = useNavigate();

  useEffect(() => {
    fetchSettings();
    fetchLoopWebhookUrl();
    fetchApiKeys();

    if (hasSlackConnected) {
      fetchSlackChannels();
      setShowSlackSettingsModal(true);
    }

    const isKlaviyoConnected = searchParams.get("is_klaviyo_connected") ?? "";
    if (isKlaviyoConnected) {
      toast.success("Klaviyo is connected");
    }

    const isKlaviyoOAuthError = searchParams.get("klaviyo_oauth_error") ?? "";
    if (isKlaviyoOAuthError) {
      toast.error("Failed to connect Klaviyo. Please reconnect and accept any permissions");
    }

  }, []);

  const fetchSettings = () => {
    integrationApi
      .integrationGetByAccountGet()
      .then((response) => {
        var settings = response.data;
        setModel(settings);
        console.log("fetchSettings", settings);
      })
      .catch((error: any) => {
        console.log("get settings error", error);
      });
  };

  const fetchLoopWebhookUrl = () => {
    integrationApi
      .integrationGetLoopWebhookUrlGet()
      .then((response) => {
        var webhookUrl = response.data;
        setLoopWebhookUrl(webhookUrl);
      })
      .catch((error: any) => {
        console.log("get loop webhook url error", error);
      });
  };

  const getKlaviyoFlowWebhookUrl = () => {
    return `https://manage.wrappedgiftcards.com/Webhooks/Klaviyo/CustomAction?accountId=${authContext.account?.accountId}`;
  };

  const getMarselloCustomRewardFulfillmentWebhookUrl = () => {
    return `https://manage.wrappedgiftcards.com/Webhooks/Marsello/RedeemCustomReward/${authContext.account?.accountId}`;
  };


  const fetchSlackChannels = () => {
    integrationApi
      .integrationGetSlackChannelsGet()
      .then((response) => {
        var channels = response.data;
        setSlackChannels(channels);
        console.log("fetchSlackChannels", channels);
      })
      .catch((error: any) => {
        console.log("get settings error", error);
      });
  };

  const toggleIntegrationEnabled = (val: any) => {
    console.log("toggleIntegrationEnabled", val);
    if (authContext.account?.billingPlan === "Free") {
      toast.error("You need to upgrade to a paid plan to use this feature.");
    } else {
      var toggleModel = {
        integration: val,
      } as ToggleIntegrationViewModel;
      integrationApi
        .integrationToggleIntegrationPost("1", toggleModel)
        .then((response) => {
          if (response.data) {
            toast.success("Integration enabled.");
          } else {
            toast.success("Integration disabled.");
          }

          fetchSettings();
        })
        .catch((error: any) => {
          console.log("An error occurred while trying to save.", error);
        });
    }
  };

  const handleLoopSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        loopWebhookSecret: model.loopWebhookSecret,
      } as LoopIntegrationViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationToggleLoopIntegrationPost("1", postModel)
        .then((response) => {
          toast.success("Loop integration enabled!");
          setShowLoopSettingsModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Loop integration.");
        });
    }
  };

  const handleMarselloSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        username: model.marselloApiUsername,
        password: model.marselloApiPassword
      } as ConnectMarselloViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationConnectMarselloPost("1", postModel)
        .then((response) => {
          toast.success("Marsello integration enabled");
          setShowMarselloInstallModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Marsello integration");
        });
    }
  };


  const handleKlaviyoSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        apiKey: model.klaviyoApiKey,
      } as KlaviyoIntegrationViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationToggleKlaviyoIntegrationPost("1", postModel)
        .then((response) => {
          toast.success("Klaviyo integration enabled!");
          setShowKlaviyoSettingsModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Klaviyo integration.");
        });
    }
  };

  const handleSegmentSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        apiKey: model.segmentApiKey,
      } as SegmentIntegrationViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationToggleSegmentIntegrationPost("1", postModel)
        .then((response) => {
          toast.success("Segment integration enabled!");
          setShowSegmentSettingsModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Segment integration.");
        });
    }
  };

  const handleZendeskSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        domain: model.zendeskDomain,
      } as ZendeskIntegrationViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationToggleZendeskIntegrationPost("1", postModel)
        .then((response) => {
          toast.success("Zendesk integration enabled!");
          setShowZendeskSettingsModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Zendesk integration.");
        });
    }
  };

  const handleChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: IntegrationViewModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSlackChannelSelected = (event: any) => {
    setIsSubmitting(false);
    const slackChannelId = event.target.value;
    console.log("slack channel id", slackChannelId);
    var index = event.target.selectedIndex;
    const slackChannelName = event.target[index].text;
    console.log("slack channel text", slackChannelName);
    const name = event.target.name;
    setModel((values: IntegrationViewModel) => ({
      ...values,
      slackChannelId: slackChannelId,
      slackChannelName: slackChannelName,
    }));
  };

  const handleSlackSubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        channelId: model.slackChannelId,
        channelName: model.slackChannelName,
      } as SlackIntegrationViewModel;

      setIsSubmitting(false);
      integrationApi
        .integrationToggleSlackIntegrationPost("1", postModel)
        .then((response) => {
          toast.success("Slack integration enabled!");
          setShowSlackSettingsModal(false);
          fetchSettings();
        })
        .catch((error: any) => {
          toast.error("Could not enable Slack integration.");
        });
    }
  };

  const getSlackInstallUrl = () => {
    var shopifyEmbedHostParam =
      new URLSearchParams(window.location.search).get("host") ||
      shopifyAppBridge.host;
    if (shopifyEmbedHostParam) {
      return `/Slack/AuthorizeSlackForShopifyEmbedded?shopifyHost=${shopifyEmbedHostParam}`;
    }
    return `/Slack/AuthorizeSlack`;
  };

  const fetchApiKeys = () => {
    integrationApi
      .integrationGetApiKeysGet()
      .then((response) => {
        var keys = response.data;
        setApiKeys(keys);
        console.log("fetchApiKeys", keys);
      })
      .catch((error: any) => {
        console.log("get apiKeys error", error);
      });
  };

  const createNewApiKey = () => {
    if (!isValidPlan) {
      toast.error("You need to upgrade to a paid plan to use this feature.");
    } else {
      integrationApi
        .integrationGenerateNewApiKeyPost()
        .then((response) => {
          toast.success("New API key created");
          fetchApiKeys();
        })
        .catch((error: any) => {
          console.log("get apiKeys error", error);
        });
    }
  };

  const confirmDisconnectKlaviyo = () => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Gift card events will no longer sync to Klaviyo"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disconnect",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          toggleIntegrationEnabled('klaviyo');
        }
      });
  };

  const confirmDisableApiKey = (token: string) => {
    Swal.default
      .fire({
        title: "Are you sure?",
        text: `Disable the API Key "${token}"?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete key",
        confirmButtonColor: "#d9534f",
      })
      .then((result) => {
        if (result.isConfirmed) {
          disableApiKey(token);
        }
      });
  };


  const disableApiKey = (token: string) => {
    integrationApi
      .integrationDisableApiKeyDelete(token)
      .then((response) => {
        if (response)
          toast.success("API key deleted and access revoked from API");
        else toast.error("Failed to delete api key");

        fetchApiKeys();
      })
      .catch((error: any) => {
        console.log("post error", error);
        toast.error("Failed to remove api key");
      });
  };

  const handleCreateApiKeySubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        description: createApiKeyDescription
      } as CreateApiKeyModel;

      setIsSubmitting(false);
      integrationApi
        .integrationGenerateNewApiKeyPost("1", postModel)
        .then((response) => {
          toast.success("API key created");
          setShowCreateApiKeyModal(false);
          fetchApiKeys();
        })
        .catch((error: any) => {
          toast.error("Could not create new API key");
        });
    }
  };

  const handleCreateApiKeyChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setCreateApiKeyDescription(value);
  };


  const handleEditApiKeySubmit = (event: any) => {
    setIsSubmitting(true);
    const form = event.currentTarget;
    //if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    //}

    setValidated(true);
    if (form.checkValidity() === true) {
      var postModel = {
        id: editApiKeyModel.id,
        description: editApiKeyModel.description
      } as EditApiKeyModel;

      setIsSubmitting(false);
      integrationApi
        .integrationEditApiKeyDescriptionPost("1", postModel)
        .then((response) => {
          toast.success("Saved");
          setShowEditApiKeyModal(false);
          fetchApiKeys();
        })
        .catch((error: any) => {
          toast.error("Could not edit new API key");
        });
    }
  };

  const handleEditApiKeyChange = (event: any) => {
    setIsSubmitting(false);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setEditApiKeyModel((values: ApiKeyModel) => ({
      ...values,
      [name]: value,
    }));
  };

  const onEditApiKeyClicked = (key: ApiKeyModel) => {
    setEditApiKeyModel(key);
    setShowEditApiKeyModal(true);
  }



  const copyToClipboard = (textToCopy: string, successMessage: string) => {
    navigator.clipboard.writeText(textToCopy);
    toast.success(successMessage);
  };

  const isValidPlan = true;
  // authContext.account?.billingPlan === BillingPlan.Pro ||
  // authContext.account?.billingPlan === BillingPlan.ProAnnual ||
  // authContext.account?.billingPlan === BillingPlan.Plus ||
  // authContext.account?.billingPlan === BillingPlan.PlusAnnual ||
  // authContext.account?.billingPlan === BillingPlan.Foundation ||
  // authContext.account?.billingPlan === BillingPlan.Enterprise;

  return (
    <React.Fragment>
      {/* <!-- Content --> */}
      <ProPlanUpsellBanner />
      <div className="container">
        {/* <!-- Navbar --> */}
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  {/* <!-- Title --> */}
                  {/* <a
                    href="https://help.wrappedgiftcard.com/en/collections/3830623-integrations"
                    target={"_blank"}
                    className="text-sm text-muted text-primary-hover font-semibold me-2 d-none d-md-block float-end"
                  >
                    <i className="bi bi-question-circle-fill"></i>
                    <span className="d-none d-sm-inline ms-2">
                      Help articles
                    </span>
                  </a> */}
                  <h1 className="h2 ls-tight">Integrations</h1>
                </div>
                <p className="text-sm pb-5">
                  Supercharge your gifting experience by integrating with the
                  best apps.
                </p>
              </div>
              <SettingsNavMenu />
            </div>
          </div>
        </header>

        {/* <!-- Main --> */}
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid">
            <div className="row pb-20">
              <div className="col-12">
                <div>
                  <div className="row g-3 g-xl-5">
                    <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="h-5 mb-5 mt-5"
                                src="/img/integrations/marsello-logo.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.marselloEnabled ? (
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      onClick={() =>
                                        setShowMarselloInstallModal(true)
                                      }
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={() =>
                                      toggleIntegrationEnabled("marsello")
                                    }
                                  >
                                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                                      <i className="bi bi-check-circle-fill me-1"></i>
                                      <span>Installed</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-0">
                            <a
                              className="h6 text-link"
                              href="#"
                              target="_blank"
                            >
                              Marsello
                            </a>
                            <p className="text-sm text-muted">
                              <div>
                                Redeem loyalty program points for omnichannel
                                gift cards
                              </div>
                            </p>
                            {model.marselloEnabled &&
                              <div className="text-end mt-10">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-neutral rounded-pill"
                                  onClick={() =>
                                    setShowMarselloRewardSettingsModal(true)
                                  }
                                >
                                  <i className="bi bi-gear me-1"></i>
                                  <span>Setup custom reward</span>
                                </button>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="h-10 mb-5"
                                src="/img/integrations/bopple-icon.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.boppleEnabled ? (
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      onClick={() =>
                                        toggleIntegrationEnabled("bopple")
                                      }
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={() =>
                                      toggleIntegrationEnabled("bopple")
                                    }
                                  >
                                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                                      <i className="bi bi-check-circle-fill me-1"></i>
                                      <span>Installed</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-0">
                            <a
                              className="h6 text-link"
                              href="#"
                              target="_blank"
                            >
                              Bopple
                            </a>
                            <p className="text-sm text-muted">
                              <div>
                                Offer omnichannel gift cards for all-in-one
                                hospitality ordering
                              </div>
                            </p>
                            {model.boppleEnabled && (
                              <>
                                <hr />
                                <p className="text-sm text-muted">
                                  Bopple install instructions{" "}
                                  <a href="#" target="_blank">
                                    here
                                  </a>
                                  .<br />
                                  Copy and paste this API key into Bopple:
                                  <InputGroup className="mt-2">
                                    <Form.Control
                                      required
                                      name={"boppleApiKey"}
                                      className="form-control text-sm"
                                      type="text"
                                      value={model.boppleApiKey ?? ""}
                                      placeholder="Bopple API key"
                                    />
                                    <Button
                                      variant="outline-secondary"
                                      title="copy to clipboard"
                                      id="button-bopple-copy-key"
                                      onClick={() =>
                                        copyToClipboard(
                                          model.boppleApiKey ?? "",
                                          "Copied to clipboard"
                                        )
                                      }
                                    >
                                      <i className="bi bi-clipboard"></i>
                                    </Button>
                                  </InputGroup>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="w-10 h-10"
                                src="/img/integrations/klaviyo.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.klaviyoEnabled ? (
                                  <div className="text-end">
                                    <a
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      href="/Klaviyo/InstallFromSettings"
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </a>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={confirmDisconnectKlaviyo}
                                  >
                                    {model.klaviyoHasInvalidToken ? (
                                      <div className="d-flex align-items-center text-warning text-sm font-semibold">
                                        <i className="bi bi-exclamation-triangle-fill me-1"></i>
                                        <span>Connection error</span>
                                      </div>

                                    ) : (
                                      <div className="d-flex align-items-center text-success text-sm font-semibold">
                                        <i className="bi bi-check-circle-fill me-1"></i>
                                        <span>Installed</span>
                                      </div>
                                    )}

                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-6">
                            <a
                              className="h6 text-link"
                              href="https://klaviyo.com/"
                              target="_blank"
                            >
                              Klaviyo
                            </a>
                            <p className="text-sm text-muted">
                              Sync gift card events and send emails via Klaviyo
                            </p>
                          </div>
                          {model.klaviyoEnabled &&
                            <div className="text-end mt-10">
                              {model.klaviyoHasInvalidToken ? (
                                <a
                                  type="button"
                                  className="btn btn-sm btn-neutral rounded-pill"
                                  href="/Klaviyo/InstallFromSettings"
                                >
                                  <i className="bi bi-plus-lg me-1"></i>
                                  <span>Reconnect</span>
                                </a>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-neutral rounded-pill"
                                  onClick={() =>
                                    setShowKlaviyoFlowModal(true)
                                  }
                                >
                                  <i className="bi bi-gear me-1"></i>
                                  <span>Setup flow action</span>
                                </button>
                              )}

                              <span onClick={confirmDisconnectKlaviyo} className="text-link text-danger ml-10 text-sm cursor-pointer">Disconnect</span>
                            </div>
                          }
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="h-10"
                                src="/img/integrations/zapier_logo.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.zapierEnabled ? (
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      onClick={() =>
                                        toggleIntegrationEnabled("zapier")
                                      }
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={() =>
                                      toggleIntegrationEnabled("zapier")
                                    }
                                  >
                                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                                      <i className="bi bi-check-circle-fill me-1"></i>
                                      <span>Installed</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-6">
                            <a
                              className="h6 text-link"
                              href="https://zapier.com"
                              target="_blank"
                            >
                              Zapier
                            </a>
                            <p className="text-sm text-muted">
                              Connect Wrapped to 7000+ apps
                            </p>
                          </div>
                          {model.zapierEnabled &&
                            <div className="text-end mt-10">
                              <button
                                type="button"
                                className="btn btn-sm btn-neutral rounded-pill"
                                onClick={() =>
                                  setShowZapierInstallModal(true)
                                }
                              >
                                <i className="bi bi-gear me-1"></i>
                                <span>Get API Key</span>
                              </button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="h-10"
                                src="/img/integrations/postco-logo.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.postCoEnabled ? (
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      onClick={() =>
                                        toggleIntegrationEnabled("postco")
                                      }
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={() =>
                                      toggleIntegrationEnabled("postco")
                                    }
                                  >
                                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                                      <i className="bi bi-check-circle-fill me-1"></i>
                                      <span>Installed</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-6">
                            <a
                              className="h6 text-link"
                              href="https://postco.co/"
                              target="_blank"
                            >
                              PostCo
                            </a>
                            <p className="text-sm text-muted">
                              <div>
                                Offer omnichannel gift cards for refunds and
                                returns
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-sm-6">
                      <div className="card shadow-4-hover">
                        <div className="card-body pb-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <img
                                alt="..."
                                className="h-10"
                                src="/img/integrations/loop-returns-logo.png"
                              />
                            </div>
                            {isValidPlan && (
                              <>
                                {!model.loopEnabled ? (
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-neutral rounded-pill"
                                      onClick={() =>
                                        setShowLoopSettingsModal(true)
                                      }
                                    >
                                      <i className="bi bi-plus-lg me-1"></i>
                                      <span>Install</span>
                                    </button>
                                  </div>
                                ) : (
                                  <div
                                    className="text-end"
                                    onClick={() =>
                                      toggleIntegrationEnabled("loop")
                                    }
                                  >
                                    <div className="d-flex align-items-center text-success text-sm font-semibold">
                                      <i className="bi bi-check-circle-fill me-1"></i>
                                      <span>Installed</span>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="mt-6">
                            <a
                              className="h6 text-link"
                              href="https://loopreturns.com/"
                              target="_blank"
                            >
                              Loop
                            </a>
                            <p className="text-sm text-muted">
                              Offer omnichannel gift cards for refunds and
                              returns
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12 mt-5">
                      <button
                        onClick={() => setShowCreateApiKeyModal(true)}
                        type="button"
                        className="btn btn-primary btn-sm float-end"
                      >
                        Create new API key
                      </button>
                      <h4 className="mt-4 mb-3">Private API Keys</h4>

                      <p>
                        Want to develop your own integration? Check out our{" "}
                        <a
                          href="https://wrappedgiftcards.readme.io"
                          target="_blank"
                        >
                          API documentation
                        </a>
                      </p>

                      <div className="card mt-5">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Token</th>
                              <th>Description</th>
                              <th>Created by</th>
                              <th>Created at</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {apiKeys &&
                              apiKeys?.map((apiKey, i) => (
                                <tr key={"apiKey-" + i}>
                                  <td>
                                    <MaskedText text={apiKey.token || ""} />
                                    {" "}
                                    <button
                                      title="copy to clipboard"
                                      onClick={() =>
                                        copyToClipboard(apiKey.token || "", "API key copied to clipboard")
                                      }
                                      type="button"
                                      className="btn btn-sm btn-square btn-neutral text-info-hover"
                                    >
                                      {" "}
                                      <i className="bi bi-clipboard"></i>
                                    </button>
                                  </td>
                                  <td>{apiKey.description}</td>
                                  <td>{apiKey.createdBy}</td>
                                  <td>
                                    {dayjs(apiKey.createdAt)
                                      .utc()
                                      .tz(authContext?.account?.ianaTimezone)
                                      .format("YYYY-MM-DD hh:mm a")}
                                  </td>

                                  <td>
                                    <button
                                      onClick={() =>
                                        onEditApiKeyClicked(apiKey)
                                      }
                                      type="button"
                                      className="btn btn-sm btn-square btn-neutral text-info-hover mx-2"
                                      title="Edit description"
                                    >
                                      <i className="bi bi-pencil-square"></i>
                                    </button>
                                    <button
                                      onClick={() =>
                                        confirmDisableApiKey(apiKey.token ?? "")
                                      }
                                      type="button"
                                      className="btn btn-sm btn-square btn-neutral text-danger-hover"
                                    >
                                      <i className="bi bi-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <Modal
        show={showLoopSettingsModal}
        onHide={() => setShowLoopSettingsModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleLoopSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Loop settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <div className="mb-5">
                <Form.Label>
                  1. Copy the webhook callback to the Loop app
                </Form.Label>
                <InputGroup className="mt-2">
                  <Form.Control
                    required
                    name={"loopWebhookUrl"}
                    className="form-control"
                    type="text"
                    value={loopWebhookUrl ?? ""}
                    placeholder="Webhook url"
                  />
                  <Button
                    variant="outline-primary"
                    title="copy to clipboard"
                    onClick={() =>
                      copyToClipboard(
                        loopWebhookUrl ?? "",
                        "Webhook url copied to clipboard"
                      )
                    }
                  >
                    <i className="bi bi-clipboard"></i>
                  </Button>
                </InputGroup>
              </div>
              <Form.Group as={Col}>
                <Form.Label>2. Enter the Loop webhook secret</Form.Label>
                <Form.Control
                  required
                  name={"loopWebhookSecret"}
                  className="form-control"
                  type="text"
                  value={model.loopWebhookSecret ?? ""}
                  placeholder="Webhook secret"
                  onChange={handleChange}
                />
              </Form.Group>
              <p>
                <small>
                  You can{" "}
                  <a
                    href="https://help.wrappedgiftcard.com/en/articles/6947048-klaviyo-integration"
                    target="_blank"
                  >
                    view instructions
                  </a>{" "}
                  here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowLoopSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showKlaviyoSettingsModal}
        onHide={() => setShowKlaviyoSettingsModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleKlaviyoSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Klaviyo settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Enter Klaviyo API key</Form.Label>
                <Form.Control
                  required
                  name={"klaviyoApiKey"}
                  className="form-control"
                  type="text"
                  value={model.klaviyoApiKey ?? ""}
                  placeholder="Klaviyo API key"
                  onChange={handleChange}
                />
              </Form.Group>
              <p>
                <small>
                  You can{" "}
                  <a
                    href="https://help.wrappedgiftcards.com/en/articles/8311584-klaviyo-integration"
                    target="_blank"
                  >
                    view instructions
                  </a>{" "}
                  here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowKlaviyoSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSegmentSettingsModal}
        onHide={() => setShowSegmentSettingsModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleSegmentSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Segment settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Enter Segment API key</Form.Label>
                <Form.Control
                  required
                  name={"segmentApiKey"}
                  className="form-control"
                  type="text"
                  value={model.segmentApiKey ?? ""}
                  placeholder="Segment API key"
                  onChange={handleChange}
                />
              </Form.Group>
              <p>
                <small>
                  You can{" "}
                  <a
                    href="https://help.wrappedgiftcard.com/en/articles/6947048-klaviyo-integration"
                    target="_blank"
                  >
                    view instructions
                  </a>{" "}
                  here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSegmentSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showZendeskSettingsModal}
        onHide={() => setShowZendeskSettingsModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleZendeskSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Zendesk settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Enter Zendesk Domain</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    required
                    name={"zendeskDomain"}
                    className="form-control"
                    type="text"
                    value={model.zendeskDomain ?? ""}
                    placeholder="zendesk domain"
                    onChange={handleChange}
                  />
                  <InputGroup.Text id="basic-addon2">
                    .zendesk.com
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <p>
                <small>
                  You can{" "}
                  <a
                    href="https://help.wrappedgiftcard.com/en/articles/7245369-zendesk-integration"
                    target="_blank"
                  >
                    view instructions
                  </a>{" "}
                  here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowZendeskSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showSlackSettingsModal}
        onHide={() => setShowSlackSettingsModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleSlackSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Slack settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Choose Slack channel you wish to receive survey responses on:
                </Form.Label>

                <Form.Select
                  name="slackChannelId"
                  onChange={handleSlackChannelSelected}
                  value={model.slackChannelId ?? ""}
                  required
                >
                  {slackChannels &&
                    slackChannels?.map((channel, i) => (
                      <option value={channel.id}>#{channel.name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowSlackSettingsModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showZapierInstallModal}
        onHide={() => setShowZapierInstallModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Zapier install instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <ol>
              <p className="mb-5">
                Navigate to Zapier and connect the{" "}
                <a
                  href="https://zapier.com/apps/Wrapped/integrations"
                  target="_blank"
                >
                  Wrapped zap
                </a>
                .
              </p>
              <p>
                You'll need to use the following API key to connect Zapier to
                your Wrapped account:
                <br />
                <InputGroup className="mt-2">
                  <Form.Control
                    required
                    name={"zapierApiKey"}
                    className="form-control"
                    type="text"
                    value={model.zapierApiKey ?? ""}
                    placeholder="Zapier API key"
                  />
                  <Button
                    variant="outline-primary"
                    title="copy to clipboard"
                    id="button-zapier-copy-key"
                    onClick={() =>
                      copyToClipboard(
                        model.zapierApiKey ?? "",
                        "API key copied to clipboard"
                      )
                    }
                  >
                    <i className="bi bi-clipboard"></i>
                  </Button>
                </InputGroup>
              </p>
            </ol>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showKlaviyoFlowModal}
        onHide={() => setShowKlaviyoFlowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Klaviyo flow instructions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <div className="mb-5">
              <Form.Label>
                1. Follow these {" "}
                <a
                  href="https://help.wrappedgiftcards.com/en/articles/9613444-klaviyo-flow-action"
                  target="_blank"
                >
                  instructions
                </a> to setup a Wrapped Klaviyo flow action.
              </Form.Label></div>
            <div className="mb-5">
              <Form.Label>
                2. Copy the destination URL into Klaviyo
              </Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  required
                  name={"klaviyoFlowWebhookUrl"}
                  className="form-control"
                  type="text"
                  value={getKlaviyoFlowWebhookUrl() ?? ""}
                  placeholder="Webhook url"
                />
                <Button
                  variant="outline-primary"
                  title="copy to clipboard"
                  onClick={() =>
                    copyToClipboard(
                      getKlaviyoFlowWebhookUrl() ?? "",
                      "Webhook url copied to clipboard"
                    )
                  }
                >
                  <i className="bi bi-clipboard"></i>
                </Button>
              </InputGroup>
            </div>
            <div className="mb-5">
              <Form.Label>
                3. Copy the webhook verification into Klaviyo
              </Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  required
                  name={"klaviyoWebhookVerificationToken"}
                  className="form-control"
                  type="text"
                  value={model.klaviyoWebhookVerificationToken ?? ""}
                  placeholder="Webhook url"
                />
                <Button
                  variant="outline-primary"
                  title="copy to clipboard"
                  onClick={() =>
                    copyToClipboard(
                      model.klaviyoWebhookVerificationToken ?? "",
                      "Verification token copied to clipboard"
                    )
                  }
                >
                  <i className="bi bi-clipboard"></i>
                </Button>
              </InputGroup>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowKlaviyoFlowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={showMarselloInstallModal}
        onHide={() => setShowMarselloInstallModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleMarselloSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Connect Marsello</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <div className="mb-5">
                <Form.Label>
                  Marsello API username:
                </Form.Label>
                <InputGroup className="mt-2">
                  <Form.Control
                    required
                    name={"marselloApiUsername"}
                    className="form-control"
                    type="text"
                    value={model.marselloApiUsername ?? ""}
                    placeholder="username"
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              <Form.Group as={Col}>

                <Form.Label>Marsello API password: <a className="text-link text-sm" onClick={() => setShowMarselloPassword(!showMarselloPassword)}>{showMarselloPassword ? "hide" : "show"}</a></Form.Label>
                <Form.Control
                  required
                  name={"marselloApiPassword"}
                  className="form-control"
                  type={showMarselloPassword ? "text" : "password"}
                  value={model.marselloApiPassword ?? ""}
                  placeholder="Password"
                  onChange={handleChange}
                />
              </Form.Group>
              <p>
                <small>
                  You can{" "}
                  <a
                    href="#"
                    target="_blank"
                  >
                    view instructions
                  </a>{" "}
                  here
                </small>
              </p>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowMarselloInstallModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showMarselloRewardSettingsModal}
        onHide={() => setShowMarselloRewardSettingsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Marsello reward settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <div className="mb-5">
              <p>
                Follow these {" "}
                <a
                  href="#"
                  target="_blank"
                >
                  instructions
                </a> to setup a custom reward in Marsello:
              </p></div>
            <div className="mb-5">
              <Form.Label>
                Copy the webhook URL
              </Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  required
                  name={"marselloWebhookUrl"}
                  className="form-control"
                  type="text"
                  value={getMarselloCustomRewardFulfillmentWebhookUrl() ?? ""}
                  placeholder="Webhook url"
                />
                <Button
                  variant="outline-primary"
                  title="copy to clipboard"
                  onClick={() =>
                    copyToClipboard(
                      getMarselloCustomRewardFulfillmentWebhookUrl() ?? "",
                      "Webhook url copied to clipboard"
                    )
                  }
                >
                  <i className="bi bi-clipboard"></i>
                </Button>
              </InputGroup>
            </div>
            <div className="mb-5">
              <Form.Label>
                Copy the webhook encryption key
              </Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  required
                  name={"marselloEncryptionKey"}
                  className="form-control"
                  type="text"
                  value={model.marselloWebhookVerificationToken ?? ""}
                  placeholder="encryption key"
                />
                <Button
                  variant="outline-primary"
                  title="copy to clipboard"
                  onClick={() =>
                    copyToClipboard(
                      model.marselloWebhookVerificationToken ?? "",
                      "Verification token copied to clipboard"
                    )
                  }
                >
                  <i className="bi bi-clipboard"></i>
                </Button>
              </InputGroup>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMarselloRewardSettingsModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateApiKeyModal}
        onHide={() => setShowCreateApiKeyModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleCreateApiKeySubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create a new API Key</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Enter a description:</Form.Label>
                <Form.Control
                  required
                  name={"createApiKeyDescription"}
                  className="form-control"
                  type="text"
                  value={createApiKeyDescription ?? ""}
                  placeholder="What will this API key be used for?"
                  onChange={handleCreateApiKeyChange}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowCreateApiKeyModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        show={showEditApiKeyModal}
        onHide={() => setShowEditApiKeyModal(false)}
      >
        <Form noValidate validated={validated} onSubmit={handleEditApiKeySubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit API Key description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Description:</Form.Label>
                <Form.Control
                  required
                  name={"description"}
                  className="form-control"
                  type="text"
                  value={editApiKeyModel.description ?? ""}
                  placeholder="What will this API key be used for?"
                  onChange={handleEditApiKeyChange}
                />
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEditApiKeyModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


    </React.Fragment>
  );
};

export default IntegrationsPage;
