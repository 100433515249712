import React, { useState, useEffect } from "react";
import { EmailApi } from "../../apiclient";
import {
  TestEmailNotificationViewModel,
} from "../../apiclient/models";
import Form from "react-bootstrap/Form";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import Modal from "react-bootstrap/Modal";
import { Button, Row, Col, InputGroup, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const SendTestEmailModal = (props: any) => {
    let emailApi = new EmailApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const [model, setModel] = useState({} as TestEmailNotificationViewModel);
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsSubmitting(true);
    const form = event.currentTarget;

    setValidated(true);
    if (form.checkValidity() === true) {
      setIsSubmitting(false);
      var postModel = {
        emailAddress: model.emailAddress,
        html: props.html,
        notificationType: props.notificationType,
        subject: props.subject
      } as TestEmailNotificationViewModel;
      emailApi
        .emailSendTestEmailPost("1", postModel)
        .then((response) => {
          var result = response.data;
           if(result){
            toast.success("Test email sent!");
            props.onHide();
           }else{
            toast.error("Failed to send email. Please pick a plan and turn on gift cards to enable this feature", {duration:10000});
           }
        })
        .catch((error: any) => {
          console.log("post error", error);
          toast.error("Error sending test email.");
        });
    }
  };

  const handleChange = (event: any) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = event.target.name;
    setModel((values: TestEmailNotificationViewModel) => ({
      ...values,
      [name]: value,
    }));
    setIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <Modal show={props.show} onHide={props.onHide}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="text-center py-5 px-5">
              <div className="icon icon-shape rounded-circle bg-opacity-30 bg-info text-info text-lg">
                <i className="bi bi-info-circle-fill"></i>
              </div>
              <h3 className="mt-7 mb-4">Send preview email?</h3>
              {/* <p className="text-sm text-muted">
              In order to send the email preview, you will need to save your changes
              </p> */}
              <div className="text-start">
              <div className="mt-5">
                <label className="form-label" htmlFor="email">
                        Email
                      </label>

                <Form.Control
                  required
                  name={"emailAddress"}
                  className="form-control"
                  placeholder="test@example.com"
                  onChange={handleChange}
                />
              </div>
              </div>
            </div>
          </div>
          <Modal.Footer>
            <Button
              className="btn btn-sm btn-neutral"
              variant={"secondary"}
              onClick={props.onHide}
            >
              Close
            </Button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-sm btn-primary"
            >
              Save &amp; send email
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default SendTestEmailModal;
