import axios from 'axios';
import { useEffect } from 'react'
import { Provider, useAppBridge } from '@shopify/app-bridge-react';
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { AppBridgeState, ClientApplication } from "@shopify/app-bridge";
import {shopifyAppBridge} from "./config"
import Cookies from 'js-cookie';

export const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.status === 401 || error.response.status === 401 || error.status === 403 || error.response.status === 403) {
           
            Cookies.set('ReturnUrl', window.location.href, {
                expires: 1, // Days before expiration
                path: '/', // Path where the cookie is accessible
                secure: true, // Only send over HTTPS
                sameSite: 'Strict', // SameSite policy,
            });
            window.location.href = "/account/login?returnUrl=" + encodeURIComponent(window.location.href);
            
        }else if(error.status == 405){
            // method not allowed - user needs to upgrade their plan
            window.location.href = "/billingplan?upgradeRequired=true";
        }
        return Promise.reject(
            error || "Something went wrong"
        )
    }
);


//axiosInstance.interceptors.request.use((config) => {

//    if(shopifyAppBridge.app && Object.keys(shopifyAppBridge.app).length > 0){

//        return getSessionToken(shopifyAppBridge.app) // requires an App Bridge instance
//            .then((token) => {
//                // Append your request headers with an authenticated token
//                if(config.headers){
//                    config.headers["Authorization"] = `Bearer ${token}`;
//                }
//                return config;
//            });
//    } 
//    else{
//        return config;
//    }
//});

