import React, { useContext, useState, useEffect } from "react";
import AccountPlanComponent from "./AccountPlanComponent";
import { Link, useSearchParams } from 'react-router-dom';
import { AuthContext } from "../../contexts/AccountContext";
import "./AccountPlanPage.css";
import toast, { Toaster } from "react-hot-toast";

const AccountPlanPage = () => {
  const authContext = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isUpgradeRequired = searchParams.get("upgradeRequired") ?? "";
    if(authContext.account && isUpgradeRequired){
      toast("Please upgrade your plan to use this feature", {
        icon: '🔒',
      });
    }
  });

  return (
    <React.Fragment>
      <AccountPlanComponent />
      
    </React.Fragment>
  );
};

export default AccountPlanPage;
