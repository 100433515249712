import React, { useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { AccountApi, AdminApi } from "../../apiclient";
import { apiConfig } from "../../config";
import {
  FormCheck,
  Button,
  Spinner,
  Form,
  Col,
  Row,
  Dropdown,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  AdminAccountListItemModel,
} from "../../apiclient/models";
import _ from "lodash";
import GridTable from "../../components/GridTable";
const dayjs = require("dayjs");
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

const AdminAccountsListPage = () => {
  let adminApi = new AdminApi(
    apiConfig.configuration,
    apiConfig.baseUrl,
    axiosInstance
  );
  const [loading, setLoading] = useState(true as boolean);
  const authContext = useContext(AuthContext);
  const [data, setData] = useState([] as AdminAccountListItemModel[]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setfilterStatus] = useState("");

  const tableColumns = [
    {
      Header: "Company Name",
      accessor: "companyName",
      Cell: ({ value, row }: any) => {
        var item = row.original as AdminAccountListItemModel;
        return (
          <React.Fragment>
            <div>
              <Link
                className="text-link font-semibold row"
                to={`/admin/account/detail/${item.accountId}`}
              >
                <div className="d-flex align-items-center col">
                  {item.companyName}<br />{item.contactEmail}
                </div>

                {/* {item.notes &&
                <div className="col align-items-center text-end text-sm pt-1">
                  <OverlayTrigger
                    key={"tooltip" + item.customerId}
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-top`}>{item.notes}</Tooltip>}
                  >
                    <i className="bi bi-card-text"></i>
                  </OverlayTrigger>
                </div>} */}
              </Link>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Account status",
      accessor: "accountStatus",
      Cell: ({ value, row }: any) => {
        var item = row.original as AdminAccountListItemModel;

        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.accountStatus === "Active" ? (<Badge bg="success" text="light">
                {item.accountStatus}
              </Badge>) : (<Badge bg="dark" text="light">
                {item.accountStatus}
              </Badge>)}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Billing plan",
      accessor: "billingPlan",
      Cell: ({ value, row }: any) => {
        var item = row.original as AdminAccountListItemModel;
        return (
          <React.Fragment>
            <span className="font-semibold text-heading">
              {item.billingPlan} {item.isTest && <Badge bg="secondary" text="dark">
                Test
              </Badge>}
              {/* {item.billingSubscriptionId &&
                <div className="col align-items-center text-end text-sm pt-1">
                  <OverlayTrigger
                    key={"tooltip" + item.accountId}
                    placement={"top"}
                    overlay={<Tooltip id={`tooltip-top`}><ol>
                      <li>Notes: {item.notes}</li>
                      <li>Additional gift card price: {item.billingAdditionalGiftCardPrice}</li>
                      <li>Free gift cards: {item.billingNumberOfFreeGiftCards}</li>
                    </ol></Tooltip>}
                  >
                    <i className="bi bi-card-text"></i>
                  </OverlayTrigger>
                </div>} */}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Date Created",
      accessor: "accountCreatedAt",
      Cell: ({ value, row }: any) => {
        var item = row.original as AdminAccountListItemModel;
        return (
          <React.Fragment>
            <span>
              {dayjs(item.accountCreatedAt)
                .utc()
                .tz(authContext?.account?.ianaTimezone)
                .format("YYYY-MM-DD hh:mm a")}
            </span>
          </React.Fragment>
        );
      },
    },
    {
      Header: "Log in",
      Cell: ({ value, row }: any) => {
        var item = row.original as AdminAccountListItemModel;
        return (
          <React.Fragment>
            <Button size="sm"
              onClick={() => loginToAccount(item.accountId!)}
              className="btn btn-sm btn-primary"
            >
              Log in to account
            </Button>
            {item.billingSubscriptionId &&
              <a
                target="_blank"
                href={`https://dashboard.stripe.com/subscriptions/${item.billingSubscriptionId}`}
                className="ml-10 btn btn-sm btn-neutral"
              >
                Manage subscription
              </a>
            }
            {/* <a
              target="_blank"
              href={`https://app.intercom.com/a/apps/jmyeajfi/companies/${item.accountId}/users`}
              className="ml-10 btn btn-sm btn-neutral"
            >
              View in Intercom
            </a> */}
          </React.Fragment>
        );
      },
    },
  ];

  const fetchData = ({ pageSize, pageIndex, search, sortBy, status }: any) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      var isDesc = sortBy[0] ? sortBy[0].desc ?? "true" : "true";
      var sortField = sortBy[0] ? sortBy[0].id ?? "accountCreatedAt" : "accountCreatedAt";

      fetchAPIData({
        limit: pageSize,
        pageIndex: pageIndex,
        skip: pageSize * pageIndex,
        search: search,
        sortField: sortField,
        isDesc: isDesc,
        status,
      });
    }
  };

  const fetchAPIData = async ({
    limit,
    pageIndex,
    skip,
    search,
    sortField,
    isDesc,
    status,
  }: any) => {
    try {
      setLoading(true);
      if (!sortField) {
        sortField = "createdAt";
      }
      const serverSortField = sortField[0].toUpperCase() + sortField.slice(1); //capitalize first letter to match server field name

      adminApi
        .adminGetAdminAccountsPageGet(
          pageIndex,
          skip,
          limit,
          serverSortField,
          isDesc,
          search
        )
        .then((response) => {
          const list = response.data.result;
          var pageSize = limit;
          var TotalCount = response.data.totalCount ?? 0;
          var pageCount = ((TotalCount + pageSize - 1) / pageSize) | 0;

          setData(list ?? ([] as AdminAccountListItemModel[]));
          setPageCount(pageCount);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const loginToAccount = (accountId: string) => {
    adminApi
      .adminAdminLoginByAccountIdGet(accountId)
      .then((response) => {
        toast.success("Logged in!");
        authContext.refresh();
        window.location.href = "/dashboard";
      })
      .catch((error: any) => {
        toast.error("Unable to login as an admin");
      });
  };

  const _handleSearch = _.debounce(
    (search: string) => {
      setSearchTerm(search);
    },
    1500,
    {
      maxWait: 1500,
    }
  );

  return (
    <React.Fragment>
      <div className="container">
        <header>
          <div className="container-fluid">
            <div className="border-bottom pt-6">
              <div className="row align-items-center">
                <div className="col-sm-12 col-12">
                  <Button size="sm" className="btn btn-primary float-end" onClick={()=>loginToAccount("6443bc5a8f7a3b15c91ac161")}>Log in to Salt demo account</Button>

                  <h1 className="h2 ls-tight">Accounts</h1>
                </div>
                <p className="text-sm pb-5">                
                  Manage accounts and update billing preferences
                </p>
              </div>
            </div>
          </div>
        </header>
        <main className="py-6 bg-surface-secondary">
          <div className="container-fluid vstack gap-3">
            <div className="card">
              <div className="col-sm-12 col-12">
                <div className="input-group input-group-sm input-group-inline float-end py-3 pr-20 width-350">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>{" "}
                  </span>
                  <input
                    id="searchTerm"
                    name="searchTerm"
                    type="text"
                    className="form-control"
                    placeholder="Search by company name or account id"
                    aria-label="Search"
                    onChange={(e) => _handleSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="p-5 pt-0">
                <GridTable
                  columns={tableColumns}
                  data={data}
                  fetchData={fetchData}
                  loading={loading}
                  pageCount={pageCount}
                  search={searchTerm}
                  goToFirstPage={undefined}
                  status={filterStatus}
                />
              </div>
              <div></div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default AdminAccountsListPage;
