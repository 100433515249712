import React, { useState, useEffect, useContext } from "react";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { AccountApi } from "../../apiclient";
import { SwitchAccountListItem, SwitchAccountLoginResult } from "../../apiclient/models";
import { Button, Row, Col, Form, Card, ListGroup } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { apiConfig } from "../../config";
import { AuthContext } from "../../contexts/AccountContext";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";

const ChooseAccountPage = () => {
  let accountApi = new AccountApi(apiConfig.configuration, apiConfig.baseUrl, axiosInstance);
  const authContext = useContext(AuthContext);
  const [model, setModel] = useState([] as SwitchAccountListItem[]);

  useEffect(() => {
    const fetchData = () => {
      accountApi.accountGetChooseAccountListGet().then((response) => {
        setModel(response.data);
      });
    };
    fetchData();
  }, []);

  const LoginToAccount = (account:SwitchAccountListItem) =>{
    accountApi
        .accountLoginToAccountGet(account.accountId)
        .then((response) => {
          var loginResult = response.data as SwitchAccountLoginResult;
          toast.success(`You have logged into account: ${account.companyName}`);
          authContext.refresh();
          window.location.href=loginResult.redirectUrl ?? "/dashboard";
        })
        .catch((error: any) => {
          toast.error(`Unable to login to account: ${account.companyName}`);
        });
      }

  return (
    <React.Fragment>
        <Row className="mt-5">
          <Col className="col-sm-12 col-lg-4 offset-lg-4">
            <Card>
              <Card.Body>
                <div className="pb-5">
              <div className="text-center"><img src="/img/logos/wrapped-logo-black.png" className="h-10" alt="Wrapped" /></div>
              </div>
                <h4 className="text-semi-muted pb-5 text-center">Choose an account to log into:</h4>
                <ListGroup>
                {model && model?.map((account,i) => (
                    <>
                    <ListGroup.Item action onClick={()=>LoginToAccount(account)}>{account.companyName} <i className="bi bi-chevron-right float-end"></i></ListGroup.Item>
                    </>
                ))}
                </ListGroup>
              </Card.Body>
          </Card>
          </Col>
        </Row>
        
    </React.Fragment>
  );
};

export default ChooseAccountPage;
