import React, { Component, useState, useContext, useEffect } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { GiftcardApi } from "../../apiclient";
import { apiConfig } from "../../config";
import { axiosInstance } from "../../ShopifyAxiosInterceptor";
import toast, { LoaderIcon, Toaster } from "react-hot-toast";
import { GiftCardManualAdjustmentViewModel } from "../../apiclient/models";
import Util from "../../js/util";

interface GiftCardManualAdjustmentModalComponentProps{
    currentBalance: number,
    giftCardId: string,
    currencyCode: string,
    onHide: any,
    show: boolean,
    onRefresh: any,
}

const GiftCardManualAdjustmentModalComponent = (props: GiftCardManualAdjustmentModalComponentProps) => {
    //modal + form
    const [model, setModel] = useState(
        {newBalance: props.currentBalance, balance: props.currentBalance} as GiftCardManualAdjustmentViewModel
    );
    const [validated, setValidated] = useState(false);
    
    let giftcardApi = new GiftcardApi(
        apiConfig.configuration,
        apiConfig.baseUrl,
        axiosInstance
      );

    const handleChange = (event: any) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;
        const name = event.target.name;
        setModel((values: GiftCardManualAdjustmentViewModel) => ({
            ...values,
            [name]: value,
        }));
        
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === true) {
            props.onHide();

            giftcardApi.giftcardPerformGiftCardManualAdjustmentPost("1", {
                adjustmentAmount: model.newBalance! - props.currentBalance,
                balance: props.currentBalance,
                giftCardId: props.giftCardId,
                newBalance: model.newBalance,
                sendEmail: model.sendEmail,
                notes: model.notes
            } as GiftCardManualAdjustmentViewModel)
            .then((response)=>{
                setModel(
                    {} as GiftCardManualAdjustmentViewModel
                ); // clear model
                
                toast.success("The gift card balance has been updated.", {duration: 5000});
                props.onHide();
                props.onRefresh();
            })
            .catch(function (error) {
                setModel(
                    {} as GiftCardManualAdjustmentViewModel
                ); // clear model
                props.onHide(); // hide modal

                toast.error("An error occurred updating the gift card balance.");
               
            });

            
        } else {
            setValidated(true);
        }
    };

    const modalLoaded = () => {
        setModel((values: GiftCardManualAdjustmentViewModel) => ({
            ...values,
            ['newBalance']: props.currentBalance,
        }));
    };

    return (
        <React.Fragment>
            <Modal
                show={props.show}
                onHide={props.onHide}
                onEntered={modalLoaded}
            >
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Adjust gift card balance
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
  
                        <Row>
                            <p className="h5">Current balance: {props.currentBalance &&
                                 <Util.CurrencyFormatter
                                    amount={props.currentBalance!}
                                    currencyCode={props.currencyCode!}
                                />
                                }</p>
                        </Row>
                        <hr className="mt-7" />
                        <Row>
                            <Form.Group>
                                <Form.Label className="h5">Change balance to:</Form.Label>

                                <div className="input-group input-group-inline"  style={{maxWidth:'50%'}}>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    name="newBalance"
                                    value={model.newBalance}
                                    onChange={handleChange}
                                    required
                                   
                                />
                                <span className="input-group-text">{props.currencyCode}</span>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    Amount required.
                                </Form.Control.Feedback>

                            </Form.Group>
                            
                            <Form.Group className="mt-5">
                                <Form.Label>
                                   Reason
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="e.g. For being a loyal customer"
                                    name="notes"
                                    value={model.notes || ""}
                                    onChange={handleChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mt-5">
                                <Form.Check
                                    id="chkSendEmail"
                                    name="sendEmail"
                                    checked={model.sendEmail}
                                    onChange={handleChange}
                                    label="Send email notification to customer?"
                                />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={props.onHide}>
                            Close
                        </Button>
                        <Button size="sm" variant="primary" type="submit">
                            Apply adjustment
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default GiftCardManualAdjustmentModalComponent;
